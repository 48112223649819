.new-order-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
  font-size: var(--smaller-font-size);
  z-index: 100;
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.new-order-form h4 {
  margin-top: 16px;
  grid-column: span 2;
  font-size: var(--h3-font-size);
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.new-order-form label {
  font-weight: bold;
  margin-bottom: 6px;
  display: block;
  color: #555;
}

.new-order-form input[type='number'],
.new-order-form input[type='text'],
.new-order-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;

  color: #333;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.new-order-form select {
  width: 100%;
}
.new-order-form textarea {
  grid-column: span 2;
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #333;
  background-color: #fff;
  resize: vertical;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.new-order-form input[type='number']:focus,
.new-order-form input[type='text']:focus,
.new-order-form textarea:focus,
.new-order-form select:focus {
  outline: none;
  border-color: #0070c9;
  box-shadow: 0 0 10px rgba(0, 112, 201, 0.3);
}
.subbmit-btn {
  margin-left: auto;
}
.new-order-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;

  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s;
}
.new-order-form .cancel-btn {
  background-color: #ff4d4d;
}
.new-order-form .cancel-btn:hover {
  background-color: #ff1a1a;
}
.new-order-form button:hover {
  background-color: #218838;
}

.new-order-form .new-order-error {
  grid-column: span 2;
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #f5c6cb;
  border-radius: 6px;
}

/* Responsywność */
@media (max-width: 768px) {
  .new-order-form {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px;
    width: 100%;
  }

  .new-order-form input[type='number'],
  .new-order-form input[type='text'],
  .new-order-form select,
  .new-order-form textarea {
    width: 100%;
  }
}
