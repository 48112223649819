/* Stylizacja kontenera formularza edycji */
.edit-order-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: fixed;
  font-size: var(--smaller-font-size);
  z-index: 100;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

/* Stylizacja nagłówka formularza edycji */
.edit-order-form h4 {
  grid-column: span 2;
  font-size: var(--h3-font-size);
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Stylizacja etykiet formularza edycji */
.edit-order-form label {
  font-weight: bold;
  margin-bottom: 6px;
  display: block;
  color: #555;
}

/* Stylizacja pól tekstowych, liczb, i select w formularzu edycji */
.edit-order-form input[type='number'],
.edit-order-form input[type='text'],
.edit-order-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Stylizacja select w formularzu edycji */
.edit-order-form select {
  width: 100%;
}

/* Stylizacja pola textarea w formularzu edycji */
.edit-order-form textarea {
  grid-column: span 2;
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #333;
  background-color: #fff;
  resize: vertical;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Stylizacja focus dla pól tekstowych, liczb, textarea i select w formularzu edycji */
.edit-order-form input[type='number']:focus,
.edit-order-form input[type='text']:focus,
.edit-order-form textarea:focus,
.edit-order-form select:focus {
  outline: none;
  border-color: #0070c9;
  box-shadow: 0 0 10px rgba(0, 112, 201, 0.3);
}

/* Stylizacja przycisku zatwierdzania w formularzu edycji */
.edit-order-form .submit-btn {
  margin-left: auto;
}

/* Stylizacja przycisków formularza edycji */
.edit-order-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;

  border-radius: 8px;
  transition: all 0.3s;
}

/* Stylizacja przycisku anulowania w formularzu edycji */
.edit-order-form .cancel-btn {
  background-color: #ff4d4d;
}

/* Stylizacja hover dla przycisku anulowania w formularzu edycji */
.edit-order-form .cancel-btn:hover {
  background-color: #ff1a1a;
}

/* Stylizacja hover dla przycisku zatwierdzania w formularzu edycji */
.edit-order-form button:hover {
  background-color: #218838;
}

/* Stylizacja błędów formularza edycji */
.edit-order-form .edit-order-error {
  grid-column: span 2;
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #f5c6cb;
  border-radius: 6px;
}

/* Stylizacja dla urządzeń mobilnych */
@media (max-width: 768px) {
  .edit-order-form {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px;
  }

  .edit-order-form input[type='number'],
  .edit-order-form input[type='text'],
  .edit-order-form textarea {
    width: calc(
      100% - 24px
    ); /* zmniejszamy szerokość o 24px, aby uwzględnić padding */
  }
}
@media (max-width: 768px) {
  .edit-order-form {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px;
    width: 100%;
  }

  .edit-order-form input[type='number'],
  .edit-order-form input[type='text'],
  .edit-order-form select,
  .edit-order-form textarea {
    width: 100%; /* Szerokość 100% dla input i textarea */
    padding: 8px; /* Mniejsze padding dla lepszej responsywności */
  }

  /* Stylizacja dla pojedynczej kolumny */
  .edit-order-form label {
    font-size: var(
      --smaller-font-size
    ); /* Mniejszy rozmiar czcionki na mniejszych ekranach */
  }

  /* Zmniejszenie marginesów dla nagłówków na mniejszych ekranach */
  .edit-order-form h4 {
    font-size: var(--h4-font-size); /* Mniejszy rozmiar czcionki nagłówka */
    margin-bottom: 15px;
  }

  /* Stylizacja błędów formularza na mniejszych ekranach */
  .edit-order-form .edit-order-error {
    font-size: var(
      --small-font-size
    ); /* Mniejszy rozmiar czcionki dla błędów */
    padding: 10px;
  }
}
