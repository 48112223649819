.user-management {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; /* Odstęp między kolumnami */
  padding: 3rem 1rem;
  font-size: var(--smaller-font-size);
}

.user-management h2 {
  grid-column: 1 / -1; /* Rozciąganie na szerokość wszystkich trzech kolumn */
  font-size: var(--h2-font-size);
  margin-bottom: 20px;
  text-align: center;
}

.user-form,
.user-list,
.edit-user-form {
  position: relative;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-form {
  grid-column: 1 / span 1; /* Pierwsza kolumna - Dodaj nowego użytkownika */
}

.user-list {
  grid-column: 2 / span 1; /* Druga kolumna - Lista użytkowników */
}

.edit-user-form {
  grid-column: 3 / span 1; /* Trzecia kolumna - Edycja użytkownika */
}

.edit-user-form h3,
.user-form h3 {
  grid-column: 1 / -1;
  font-weight: 400;
  margin-bottom: 10px;
}

.edit-user-form h3 .title {
  color: #4caf50;
  font-weight: bold;
}

.edit-user-form .archive {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 16px; */
  position: absolute;
  top: 10px;
  right: 10px;
}

.edit-user-form .buttons,
.user-form .buttons {
  grid-column: 1 / -1;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .user-management {
    grid-template-columns: 1fr; /* Jedna kolumna na mniejszych ekranach */
  }

  .edit-user-form {
    grid-column: 1 / -1; /* Rozciągnięcie na pełną szerokość w wersji mobilnej */
  }
}

.user-form label,
.edit-user-form label {
  display: block;
  margin-bottom: 10px;
  font-size: var(--smaller-font-size);
}

.user-form input,
.user-form select,
.edit-user-form input,
.edit-user-form select {
  width: 100%; /* Szerokość inputów i selectów - pełna szerokość kontenera */
  padding: 8px;
  margin-top: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Zapewnienie, że szerokość obejmuje również padding */
}

.user-list {
  position: relative;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-list h3,
.edit-user-form h3,
.user-form h3 {
  font-size: var(--h3-font-size);
  font-weight: 400;
  margin-bottom: 30px;
}

.user-scroll-list {
  max-height: 100%; /* Maksymalna wysokość listy z przewijaniem */
  overflow-y: auto; /* Przewijanie pionowe */
}

.user-scroll-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-scroll-list li {
  padding: 8px;

  cursor: pointer;
}

.user-scroll-list li:hover {
  background-color: #f0f0f0;
}

.onBack {
  position: absolute;
  top: 0;
  right: 0;
}
.user-error {
  position: absolute;
  width: 85%;

  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: bold;
  background-color: rgba(244, 67, 54, 0.8);
  border: 1px solid rgb(244, 67, 54);
  color: white;
  padding: 16px;
  border-radius: 5px;
  text-align: center;
}

.user-management .button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;

  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s;
}
.user-management .button:hover {
  background-color: #28a700;
  color: #ddd;
}
.user-management .cancel-btn {
  background-color: #ff4d4d;
}

/* Stylizacja hover dla przycisku anulowania w formularzu edycji */
.user-management .cancel-btn:hover {
  background-color: #ff1a1a;
}
@media (max-width: 1200px) {
  .user-management {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Dwie kolumny na średnich ekranach */
    grid-gap: 15px;
  }

  .user-form,
  .user-list,
  .edit-user-form {
    grid-column: span 2; /* Rozciągnięcie na dwie kolumny na średnich ekranach */
  }
}

@media (max-width: 768px) {
  .user-management {
    grid-template-columns: 1fr; /* Jedna kolumna na mniejszych ekranach */
  }

  .user-form,
  .user-list,
  .edit-user-form {
    grid-column: 1 / -1; /* Rozciągnięcie na pełną szerokość w wersji mobilnej */
  }
}
