/* Footer.css */

.footer {
  /* position: relative; */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 0.7rem;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center; /* Wyrównanie w pionie */
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer img {
  position: absolute;
  left: 100px;
  bottom: 110%;
  height: 100px; /* Dostosowanie wysokości obrazka */
  margin-right: 10px; /* Margines po prawej stronie obrazka */
  filter: grayscale(1);
  display: none;
}
