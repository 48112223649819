/* Global styles for the overlay */
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(
    8px
  ); /* Blurred background for a more professional look */
}

/* Dialog styles */
.confirmation-dialog {
  background-color: #ffffff;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center;
  max-width: 300px;
  width: 100%;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  transition: background-color 0.4s ease, transform 0.3s ease;
  position: relative;
  animation: fadeIn 0.4s ease-out; /* Smooth appearance animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.confirmation-dialog.success {
  background-color: #4caf50; /* Green background for success */
  color: #ffffff; /* White text for success */
  animation: successBounce 0.5s ease-out; /* Bounce animation for success */
}

@keyframes successBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Icon container styles */
.confirmation-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-icon {
  font-size: 2.5em;
  color: #3498db; /* Blue icon */
  transition: color 0.3s ease;
}

.confirmation-icon.success-icon {
  color: #4caf50; /* Green icon for success */
}

/* Message styles */
.confirmation-message {
  font-size: var(--normal-font-size);
  margin-top: 40px;
  margin-bottom: 20px;
  color: #333333; /* Darker text color */
}

.confirmation-dialog.success .confirmation-message {
  color: #ffffff; /* White text for success */
}

/* Button container styles */
.confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 35px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: var(--small-font-size);
}

.confirm-button {
  background-color: #28a745;
  color: #ffffff;
}

.confirm-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.cancel-button {
  background-color: #e74c3c;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

.confirm-button:focus,
.cancel-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}
