/* Ogólne style kontenera */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 8em 0;
  position: relative;
}

/* Ulepszony wygląd boxa */
.box {
  width: 100%;
  background-color: #fff;
  /* padding: 2em 0rem; */
  border-radius: 12px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  font-size: var(--small-font-size);
  font-weight: 300;
}

/* Nagłówek boxa */
.box h3 {
  font-size: var(--h2-font-size);
  margin-bottom: 1.5em;
  text-align: center;
  color: #333;
}

/* Style filtrów */

.filters {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 50px;
}

.filters label {
  display: block;
  margin-bottom: 8px;
  font-size: var(--smaller-font-size);
  color: #333;
}

.filters select {
  width: 140px;
  padding: 8px;
  font-size: var(--smaller-font-size);
  border-radius: 4px;
}

.filters button {
  padding: 10px 20px;
  font-size: var(--smaller-font-size);
  background-color: #28a745; /* Zielony kolor przycisków */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filters button:hover {
  background-color: #218838; /* Ciemniejszy odcień zielonego po najechaniu */
}

/* Style tabeli */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

thead {
  color: #fff;
  font-size: var(--smaller-font-size);
  background-color: #28a745;
}
@media (min-width: 768px) {
  td[data-label='Ile:'] {
    width: 16px;
  }
  td[data-label='Model:'] {
    width: 160px;
  }
  td[data-label='Wersja:'] {
    width: 1px;
  }
  td[data-label='Wyposażenie:'] {
    width: 251px;
    text-align: left;
  }
}
th,
td {
  padding: 4px 5px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  cursor: pointer;
  position: relative;
}
.white {
  background-color: #fff;
}
/* Kolory dla różnych klas */
.blue {
  background-color: #e7f0ff;
}

.red {
  background-color: #f8d7da;
}

.green {
  background-color: #d4edda;
}

.yellow {
  background-color: #fff3cd;
}

.archived {
  background-color: #e0aaff;
}

/* Efekty hover dla różnych klas */
tr.blue:hover {
  background-color: #cce5ff;
}

tr.red:hover {
  background-color: #f5c6cb;
}

tr.green:hover {
  background-color: #c3e6cb;
}

tr.yellow:hover {
  background-color: #ffeeba;
}

/* Style przycisków */
.delete-btn,
.edit-btn,
.archive-btn,
.restore-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 50%;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;

  transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-btn {
  background-color: #dc3545;
}

.edit-btn {
  background-color: #ffc107;
}

.restore-btn {
  background-color: #28a745;
}

.archive-btn {
  background-color: #007bff;
}

.delete-btn:hover {
  background-color: #c82333;
  transform: scale(1.1);
}

.edit-btn:hover {
  background-color: #e0a800;
  transform: scale(1.1);
}

.restore-btn:hover {
  background-color: #218838;
  transform: scale(1.1);
}

.archive-btn:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

/* Brak wyników */
.no-results {
  font-size: var(--h2-font-size);
  font-weight: bold;
  color: #555;
  text-align: center;
  margin-top: 1em;
  padding: 1em;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.disabled-filter {
  opacity: 0.5;
  pointer-events: none;
}
.active {
  background-color: #dc3545 !important;
}
.archive {
  color: #dc3545;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 768px) {
  .filters {
    display: grid; /* Używamy siatki dla responsywnego układu */
    grid-template-columns: repeat(2, 1fr); /* Dwa kolumny */
    gap: 10px;
    margin-bottom: 30px;
  }
  .filters .full-width {
    grid-column: span 2; /* Element z klasą 'full-width' zajmuje dwie kolumny */
  }
  .filters select {
    width: 100%; /* Szerokość selektora na pełną szerokość kontenera */
  }

  .filters button {
    grid-column: span 2; /* Przyciski zajmują dwie kolumny */
    width: 100%; /* Szerokość przycisku na pełną szerokość kontenera */
  }
  table {
    border: 0;
  }

  thead {
    display: none;
  }

  tbody,
  tr {
    margin-bottom: 20px;
  }

  td {
    display: block;
    text-align: right;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    position: relative;
    height: 40px;
  }

  td::before {
    text-align: left;
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 40%;

    padding-left: 10px;
    font-weight: bold;
    white-space: nowrap;
    /* background: #f9f9f9; */
    border-right: 1px solid #ddd;
  }

  td:last-child {
    border-bottom: none;
  }
}
