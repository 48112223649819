/* ======================= Google Fonts ======================= */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* ======================= Variables CSS ======================= */
:root {
  /* =================== Colors =================== */
  --first-color: #5d3891;
  --second-color: #f99417;
  --hover-color: #4f307a;
  --text-color: #c4c4c4;
  --white-color: #f7f7f7;
  --dark-color: #000;
  --body-color: #1d1b1b;

  /* ============== Font and Typography ============== */
  /*.5rem = 8px | 1rem = 16px ... */

  --h1-font-size: 1.75rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /* ============== Font Weight ============== */
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /* ============== Box Shadow ============== */
  --shadow: 0px 5px 20px 0px rgba(69 67 96 / 10%);

  /* ============== Border Radius ============== */
  --border-radius: 8px;
}

/* Responsive typography */

@media screen and (min-width: 992px) {
  :root {
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
} /* ======================= Base ======================= */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-image: url('./img/background.jpg');
  background-size: cover; /* Skalowanie tła */
  background-repeat: no-repeat; /* Brak powtarzania */
  background-position: center; /* Wyśrodkowanie */
  position: relative; /* Ustawienie kontenera tła */
  min-height: 100vh;
}

/* Pseudo-element jako overlay */
body::before {
  content: '';
  position: fixed; /* Fixed, aby overlay pokrywał całą stronę */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Półprzezroczysty overlay */
  z-index: -1; /* Ustawienie overlay nad tłem */
}
/* ======================= Resuable CSS Classes ======================= */

.container {
  max-width: 1400px;
}

header {
  position: fixed;
  right: 50%;
  transform: translate(50%, 0%);
  background-color: #f9f9f9;
  color: #000;
  padding: 10px 20px;
  width: 60%;
  font-size: var(--smaller-font-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 8px 8px;
  z-index: 999;
}
.header-left {
  flex: 1;
  text-align: left;
}

.header-center {
  flex: 1;
  text-align: center;
}

.header-right {
  flex: 2;
  text-align: right;
}
.header-logged {
  display: flex;
  align-items: center;
  justify-content: end;
}
.header-title {
  /* font-size: 1.5rem; */
  margin: 0;
}
.header-title img {
  width: 80px;
}
.header-user {
  display: inline-block;
  margin: 0 10px;
}
.header-user span {
  font-weight: var(--font-bold);
}
.header-date {
  font-size: 0.9rem;
  margin: 0;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;

  cursor: pointer;
  margin-left: 10px;
  border-radius: 8px;
}

.logout-button:hover {
  background-color: #c82333;
}
.alert {
  background-color: #4caf50; /* Zielony kolor alertu */
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.buttons {
  display: flex;
}

.license-expired-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}
.license-expired-container {
  width: 90%;
  max-width: 700px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
}

.license-expired {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.license-expired-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.license-expired-content {
  text-align: left;
}

.license-expired-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #e57373; /* czerwony kolor */
}

.license-expired-message {
  font-size: 18px;
  line-height: 1.6;
  color: #333333;
}

/* Responsywne dostosowanie */
@media (max-width: 768px) {
  .license-expired-container {
    width: 95%;
    padding: 20px;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  header {
    width: 100%;
  }
  .header-left {
    display: none;
  }
}
