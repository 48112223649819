/* Global styles */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  position: relative;
  width: 400px;
  backdrop-filter: blur(25px);
  border: 2px solid var(--primary-color);
  border-radius: 15px;
  padding: 4.5em 2.5em 4em 2.5em;
  color: var(--second-color);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.login-form label {
  font-size: var(--small-font-size);
  color: #ddd;
  margin-bottom: 30px;
}

.login-form input[type='text'],
.login-form input[type='password'] {
  color: #fff;
  margin-top: 4px;
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: var(--normal-font-size);
}

.login-form button {
  width: 100%;

  padding: 14px;
  background-color: #38b782;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--normal-font-size);
  transition: background-color 0.3s ease; /* Efekt przejścia */
  text-transform: uppercase;
}

.login-form button:hover {
  background-color: #38b770; /* Efekt zmiany koloru przy najechaniu */
}

.error-message {
  position: absolute;
  top: 4%;
  right: 50%;
  width: 85%;
  font-weight: bold;
  font-size: var(--normal-font-size);
  transform: translate(50%, -50%);
  background-color: rgba(244, 67, 54, 0.8);
  border: 1px solid rgb(244, 67, 54);
  color: white;
  padding: 16px;
  border-radius: 5px;
  text-align: center;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.logo img {
  width: 80px;
  height: auto;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .login-form {
    width: 90%;
    padding: 3em 2em 2em 2em;
  }
}
