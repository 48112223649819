/* CompanyList.css */
.company-list-container {
  position: relative;
  width: 100%;
}
.company-list {
  border-radius: 8px;
  padding: 3em 2rem;
}

.company-list h2 {
  font-size: var(--h2-font-size);
  margin-bottom: 45px;
  text-align: center;
}

.companies-wrapper {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Automatyczne dostosowanie do szerokości */
  gap: 20px; /* Odstęp między elementami */
}

.company-item {
  padding: 15px;
  background-color: #ddd;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s;
  position: relative;
  border-bottom: 2px solid #28a745;
}
.company-item:first-child {
  border-bottom: 2px solid #dc3545;
}
.company-item:first-child:hover {
  background-color: #dc3545;
}
.company-item:hover {
  background-color: #28a745;
  color: #fff;
}

.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  width: 60%;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.company-item:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.back-btn,
.user-settings,
.onBack {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: #28a745;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 0 0 0 12px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s;
}
.back-btn {
  border-radius: 0 0 12px 0;
}
.back-btn:hover,
.onBack:hover,
.user-settings:hover {
  background-color: #28a700;
  color: #ddd;
}
.back-btn.active {
  background-color: #ff4d4d;
}
.back-btn.active:hover {
  background-color: #f44336;
}
.user-settings {
  position: absolute;
  right: 0;
}
/* Responsywność */
@media (max-width: 1200px) {
  .companies-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }

  .company-item {
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .company-list {
    padding: 15px;
  }

  .companies-wrapper {
    grid-template-columns: 1fr;
  }

  .company-item {
    padding: 10px;
  }

  .tooltip {
    width: 80%;
  }

  .back-btn,
  .user-settings,
  .onBack {
    padding: 8px; /* Mniejsze paddingi przycisków */
  }
}
